<template>
<div>
  <el-dialog
      title="处理"
      :visible.sync="ModelState"
      :show-close="false"
      :close-on-click-modal="false">
    <table class="view-table">
      <tr>
        <th>维修来源</th>
        <td colspan="3">
          <span v-if="detiltable.maintenanceSource==0">巡检事件</span>
          <span v-if="detiltable.maintenanceSource==1">管网设施</span>
          <span v-if="detiltable.maintenanceSource==2">其他维修</span>
        </td>
      </tr>
      <tr>
        <th>事件类型</th>
        <td colspan="3">{{detiltable.eventName}}</td>
      </tr>
      <tr>
        <th>维修人员</th>
        <td>{{ detiltable.maintenanceUserName }}</td>
        <th>维修期限</th>
        <td>{{ detiltable.maintenanceTime }}</td>
      </tr>
      <tr>
        <th>维修原因</th>
        <td colspan="3">{{ detiltable.maintenanceReason }}</td>
      </tr>
      <tr>
        <th>联系人</th>
        <td>{{ detiltable.contactPeople }}</td>
        <th>联系电话</th>
        <td>{{ detiltable.contactPhone }}</td>
      </tr>
      <tr>
        <th>所在地址</th>
        <td colspan="3">{{detiltable.maintenanceAddress}}</td>
      </tr>
    </table>
    <el-form ref="Eventform" :model="Eventform" label-width="100px" style="margin-top: 20px;height:280px;overflow: auto">
      <el-form-item label="维修前">
        <el-upload
            action="#"
            :auto-upload="true"
            :http-request="repairBeforUploadFun"
            :id="repairBeforFilefileList.length>9?'disUoloadSty':''"
            :on-change="beforHFhandleChangeImg"
            :before-remove="beforhandleRemove"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
            :file-list="repairBeforFilefileList">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="维修内容">
        <el-input v-model="Eventform.maintenanceContent" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="维修后">
        <el-upload
            action="#"
            :auto-upload="true"
            :http-request="repairAfterUploadFun"
            :id="repairBeforFilefileList.length>9?'disUoloadSty':''"
            :on-change="afterChangeImg"
            :before-remove="afterhandleRemove"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
            :file-list="repairAfterFilefileList">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="满意度">
        <el-radio-group v-model="Eventform.maintenanceSatisfaction">
          <el-radio label="0" border>满意</el-radio>
          <el-radio label="1" border>一般</el-radio>
          <el-radio label="2" border>不满意</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="用户签字">
        <el-upload
            action="#"
            list-type="picture-card"
            :auto-upload="true"
            :http-request="UploadFun"
            :id="fileList.length>0?'disUoloadSty':''"
            :on-change="HFhandleChangeImg"
            :before-remove="handleRemove"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
            :file-list="fileList">
          <i slot="default" class="el-icon-upload"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import {managemaintenance, uploadImg} from "../../RequestPort/maintenance";

export default {
  name: "dispose",
  props:{
    ModelState: {
      type: Boolean,
      default: false
    },
    EventIds: {
      type:Number,
      default: 0
    },
    detiltable: {
      type:Object
    }
  },
  data(){
    return{
      Eventform:{
        beforeUrls:[],
        afterUrls:[],
        maintenanceContent:'',
        maintenanceSatisfaction:'0',
        signUrl:''
      },
      FileFormData: new FormData(),
      repairBeforFileFormData: new FormData(),
      repairAfterFileFormData: new FormData(),
      fileList:[],
      repairBeforFilefileList:[],
      repairAfterFilefileList:[]
    }
  },
  methods:{
    UploadFun(e){
      this.FileFormData.append('file',e.file)
      uploadImg(this.FileFormData).then((res)=> {
        this.FileFormData.delete('file')
        this.Eventform.signUrl = res.data.url
      })
    },
   async repairBeforUploadFun(e){
      this.repairBeforFileFormData.append('file',e.file)
    await  uploadImg(this.repairBeforFileFormData).then((res)=> {
        this.repairBeforFileFormData.delete('file')
        this.Eventform.beforeUrls.push(res.data.url)
      })
    },
    repairAfterUploadFun(e){
      this.repairAfterFileFormData.append('file',e.file)
      uploadImg(this.repairAfterFileFormData).then((res)=> {
        this.repairAfterFileFormData.delete('file')
        this.Eventform.afterUrls.push(res.data.url)
      })
    },
    beforhandleRemove(file) {
      let index = this.getArrayIndex(this.repairBeforFilefileList,file);
      this.Eventform.beforeUrls.splice(index,1)
    },
    afterhandleRemove(file) {
      let index = this.getArrayIndex(this.repairAfterFileFormData,file);
      this.Eventform.afterUrls.splice(index,1)
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    handleRemove(file) {
      this.fileList = []
      this.Eventform.signUrl = ''
    },
    HFhandleChangeImg(file, fileList) {
      this.fileList = fileList
    },
    beforHFhandleChangeImg(file, fileList) {
      this.repairBeforFilefileList = fileList
    },
    afterChangeImg(file, fileList) {
      this.repairAfterFilefileList = fileList
    },
    close(){
      this.$emit("closeModal", false);
    },
    submitForm(){
      this.Eventform.id = this.EventIds
      managemaintenance(this.Eventform).then(res=>{
        if(res.code ===200){
          this.$message.success('操作成功！')
          this.close()
        }
      })
    }
  },

}
</script>

<style scoped>
</style>
