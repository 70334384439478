<template>
  <div class="contain">
    搜索条件：
    <el-input size="mini" type="text" v-model="paramsData.searchText" placeholder="创建人/处理人" style="width: 200px"></el-input>
    维修状态：
    <el-select clearable  size="mini" style="width:130px;" class="EquipmentMarginLeft" v-model="paramsData.maintenanceStatus" placeholder="维修状态">
      <el-option
          v-for="item in userStatus"
          :key="item.id"
          :label="item.label"
          :value="item.id">
      </el-option>
    </el-select>
    维修来源：
    <el-select clearable  size="mini" style="width:130px;" class="EquipmentMarginLeft" v-model="paramsData.maintenanceSource" placeholder="维修来源">
      <el-option
          v-for="item in sourceStatus"
          :key="item.id"
          :label="item.label"
          :value="item.id">
      </el-option>
    </el-select>
    时间范围：
    <el-date-picker
        size="mini"
        v-model="DataPicker"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        value-format="yyyy-MM-dd"
        end-placeholder="结束日期">
    </el-date-picker>
    <el-button type="primary" icon="el-icon-search" @click="search" size="mini" style="margin-left: 10px">搜索</el-button>
    <el-button @click="resize" type="primary" size="mini" class="EquipmentMarginLeft"
               icon="el-icon-refresh">重置
    </el-button>
    <el-button type="success" size="mini" icon="el-icon-plus" @click="addGroup">添加</el-button>
    <el-button type="danger" style="position: absolute;right: 20px"  size="mini" class="EquipmentMarginLeft"
               @click="MutiDeleteFun" icon="el-icon-delete">批量删除
    </el-button>
    <el-table :data="GroupList"
              style="margin-top: 10px"
              @selection-change="handleSelectionChange"
              ref="multipleTable"
              height="750"
              :row-style="{height:'30px'}"
              :cell-style="{padding:'10px'}"
              :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">
      <el-table-column align="center" prop="State" type="selection" width="80"></el-table-column>
      <el-table-column align="center" prop="maintenanceSource" label="维修来源" sortable>
        <template slot-scope="{row}" >
          <span v-if="row.maintenanceSource==0">巡检事件</span>
          <span v-if="row.maintenanceSource==1">管网设施</span>
          <span v-if="row.maintenanceSource==2">其他维修</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="maintenanceReason" label="维修原因" sortable></el-table-column>
      <el-table-column align="center" prop="maintenanceAddress" label="所在地址" sortable show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="maintenanceStatus" label="维修状态" sortable>
        <template slot-scope="{row}" >
          <el-tag v-if="row.maintenanceStatus==1" type="success">已完成</el-tag>
          <el-tag v-else type="danger">待维修</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="maintenanceTime" label="维修期限" sortable></el-table-column>
      <el-table-column align="center" prop="createBy" label="创建人" sortable show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" sortable></el-table-column>
      <el-table-column align="left" prop="State" label="操作">
        <template slot-scope="{row}">
          <el-button v-if="row.maintenanceStatus==0" type="text" @click="handle(row)" size="small">处理</el-button>
          <el-button type="text" @click="seeDetil(row)" size="small">详情</el-button>
          <el-button type="text" @click="del(row)" size="small" style="color:red">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          style="margin-top:10px;"
          background
          layout="sizes,prev, pager, next,jumper"
          :total="total"
          :page-sizes="[10,30,50,100]"
          :page-size="paramsData.size"
          :current-page="paramsData.current"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
        title="维修信息详情"
        :visible.sync="detilVisible">
          <table class="view-table">
            <tr>
              <th>维修来源</th>
              <td>
                <span v-if="detiltable.maintenanceSource==0">巡检事件</span>
                <span v-if="detiltable.maintenanceSource==1">管网设施</span>
                <span v-if="detiltable.maintenanceSource==2">其他维修</span>
              </td>
              <th>维修状态</th>
              <td>
                <span v-if="detiltable.maintenanceStatus==0">待维修</span>
                <span v-if="detiltable.maintenanceStatus==1">已完成</span>
              </td>
            </tr>
            <tr>
              <th>事件类型</th>
              <td colspan="3">{{detiltable.eventName}}</td>
            </tr>
            <tr>
              <th>维修编码</th>
              <td colspan="3">{{detiltable.maintenanceCode}}</td>
            </tr>
            <tr>
              <th>维修人员</th>
              <td>{{ detiltable.maintenanceUserName }}</td>
              <th>维修期限</th>
              <td>{{ detiltable.maintenanceTime }}</td>
            </tr>
            <tr>
              <th>维修原因</th>
              <td colspan="3">{{ detiltable.maintenanceReason }}</td>
            </tr>
            <tr>
              <th>联系人</th>
              <td>{{ detiltable.contactPeople }}</td>
              <th>联系电话</th>
              <td>{{ detiltable.contactPhone }}</td>
            </tr>
            <tr>
              <th>所在地址</th>
              <td colspan="3">{{detiltable.maintenanceAddress}}</td>
            </tr>
            <tr>
              <th>维修内容</th>
              <td colspan="3">{{ detiltable.maintenanceContent }}</td>
            </tr>
            <tr v-if="detiltable.maintenanceSatisfaction">
              <th>满意度</th>
              <td colspan="3">
                <span v-if="detiltable.maintenanceSatisfaction==0">好</span>
                <span v-if="detiltable.maintenanceSatisfaction==1">一般</span>
                <span v-if="detiltable.maintenanceSatisfaction==2">差</span>
              </td>
            </tr>
            <tr v-if="detiltable.signUrl">
              <th>用户签字</th>
              <td colspan="3">
                <viewer :images="detiltable.signUrl">
                    <img :src="detiltable.signUrl" alt="" style="height: 100px;width: 100px">
                </viewer>
              </td>
            </tr>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detilVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="添加维修信息"
        :visible.sync="dialogVisible"
        width="40%"
        :close-on-click-modal="false"
        @close="clearnF('form')">
      <el-form ref="form" :model="form" label-width="120px" :rules="rule">
        <el-form-item label="维修来源：" prop="maintenanceSource">
          <el-select clearable  style="width:200px;" class="EquipmentMarginLeft" v-model="form.maintenanceSource" placeholder="维修来源" @change="clearsource">
            <el-option
                v-for="item in sourceStatustwo"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div v-if="form.maintenanceSource==0">
          <el-form-item label="事件类型：" prop="eventName">
            <el-input v-model="form.eventName" @focus="chooseEvent"></el-input>
          </el-form-item>
        </div>
        <div v-if="form.maintenanceSource==1">
          <el-form-item label="管网设施：" prop="eventName">
            <el-input v-model="form.eventName" @focus="chooseEquipment"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="所在地址：" prop="maintenanceAddress">
          <el-input v-model="form.maintenanceAddress" :disabled="form.maintenanceSource==1||form.maintenanceSource==0" @focus="showMapModel = true"></el-input>
        </el-form-item>
        <el-form-item label="维修原因：" prop="maintenanceReason">
          <el-input v-model="form.maintenanceReason" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="维修人员：" prop="maintenanceUserId">
          <el-select clearable  style="width:100%;" class="EquipmentMarginLeft" value-key="userId" v-model="form.person" placeholder="维修人员" @change="choosePerson" >
            <el-option
                v-for="item in maintenList"
                :key="item.userId"
                :label="item.userName"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="维修期限：" prop="maintenanceTime">
          <el-date-picker
              style="width:100%;"
              v-model="form.maintenanceTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择维修期限日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="联系人：" prop="contactPeople">
          <el-input v-model="form.contactPeople"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="contactPhone">
          <el-input v-model="form.contactPhone" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitfrom('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :title="choosetype==1?'选择事件':'选择设施'"
        :visible.sync="eventVisible"
    >
      <el-table ref="singleTable" :data="tableData" highlight-current-row @current-change="chooseCurrentChange" style="width: 100%;height:500px;overflow: auto">
        <el-table-column align="center" type="index" label="序号"></el-table-column>
          <el-table-column v-if="choosetype==1" property="eventCodeName" label="事件类型"></el-table-column>
          <el-table-column v-if="choosetype==1" property="createBy" label="创建人"></el-table-column>
          <el-table-column v-if="choosetype==1" property="createTime" label="创建时间"></el-table-column>
          <el-table-column v-if="choosetype==1" property="eventAddress" label="地址"></el-table-column>
          <el-table-column v-if="choosetype==2" property="equipmentName" label="设备名称"></el-table-column>
          <el-table-column v-if="choosetype==2" property="equipmentType" label="设备类型"></el-table-column>
          <el-table-column v-if="choosetype==2" property="equipmentSpecifications" label="型号"></el-table-column>
          <el-table-column v-if="choosetype==2" property="equipmentDepartment" label="所属部门"></el-table-column>
          <el-table-column v-if="choosetype==2" property="equipmentProducers" label="生产商"></el-table-column>
          <el-table-column v-if="choosetype==2" property="checkingPoint" label="是否是巡检点">
            <template slot-scope="{row}">
              <span>{{row.checkingPoint==0?'否':'是'}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="choosetype==2" property="equipmentStatus" label="状态">
            <template slot-scope="{row}">
              <el-tag v-if="row.equipmentStatus==0" type="success">停用</el-tag>
              <el-tag v-if="row.equipmentStatus==1" type="danger">在用</el-tag>
              <el-tag v-if="row.equipmentStatus==2">废弃</el-tag>
            </template>
          </el-table-column>
      </el-table>
      <div style="text-align: center">
        <el-pagination
            @size-change="eventsSizeChange"
            style="margin-top:10px;"
            background
            layout="sizes,prev, pager, next,jumper"
            :total="eventstotal"
            :page-sizes="[10,30,50,100]"
            :page-size="eventsData.size"
            :current-page="eventsData.current"
            @current-change="eventsCurrentChange"
        >
        </el-pagination>
      </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="eventVisible = false">取 消</el-button>
        <el-button v-if="choosetype==1" type="primary" @click="submitevents">确 定</el-button>
        <el-button v-if="choosetype==2" type="primary" @click="submitequipment">确 定</el-button>
    </span>
    </el-dialog>
    <el-dialog
        :visible.sync="showMapModel"
        :show-close="false"
        :close-on-click-modal="false">
      <mapDialog  @closeModal="closeMapModel"></mapDialog>
    </el-dialog>
    <Dispose :ModelState="showModel" @closeModal="closeModel" :EventIds="eventID" :detiltable="detiltable"></Dispose>
  </div>
</template>

<script>
import {
  maintenancePage,
  deleteBatchgroup,
  eventPage,
  equipmentPage,
  ByPostpeople,
  savelistroup, Pagedetil
} from "../../RequestPort/maintenance";
import Dispose from './dispose'
import mapDialog from "../event/mapDialog";
export default {
  name: "eqiupmentGroup",
  components: {Dispose,mapDialog},
  data(){
    return{
      showModel: false,
      showMapModel: false,
      eventID: null,
      total:0,
      eventstotal:0,
      title:1,
      choosetype:1,
      dialogVisible:false,
      detilVisible:false,
      eventVisible:false,
      tableData:[],
      maintenList:[],
      detiltable: {},
      eventsData:{
        current:1,
        size: 100,
      },
      paramsData:{
        current:1,
        size: 100,
        searchText:'',
        maintenanceStatus:'-1',
        maintenanceSource:'-1'
      },
      form:{
        contactPeople:'',
        contactPhone:'',
        maintenanceSource:'0',
        maintenanceAddress:'',
      },
      DataPicker:[],
      multipleSelection:[],
      GroupList:[],
      userStatus:[{label:'全部',id:'-1'},{label:'已完成',id:'1'},{label:'待维修',id:'0'}],
      sourceStatus:[{label:'全部',id:'-1'},{label:'管网设施',id:'1'},{label:'巡检事件',id:'0'},{label:'其他维修',id:'2'}],
      sourceStatustwo:[{label:'巡检事件',id:'0'},{label:'管网设施',id:'1'},{label:'其他维修',id:'2'}],
      rule:{
        maintenanceSource:[{ required: true, message: '请选择维修来源', trigger: 'blur' }],
        maintenanceTime:[{ required: true, message: '请选择维修期限', trigger: 'blur' }],
        maintenanceUserId:[{ required: true, message: '请选择', trigger: 'blur' }],
        contactPhone:[{ required: true, message: '请填写联系方式', trigger: 'blur' }],
        contactPeople:[{ required: true, message: '请填写联系人', trigger: 'blur' }],
      },
      currentRow:null,

    }
  },
  mounted() {
    this.loadlist(this.paramsData)
  },
  methods:{
    handle(row) {
      this.showModel = true
      this.eventID = row.id
      Pagedetil({id:row.id}).then(res=>{
        this.detiltable = res.data
      })
    },
    closeModel (res) {
      this.showModel = res
      this.loadlist(this.paramsData)
    },
    closeMapModel(address){
      this.showMapModel = false
      this.form.maintenanceAddress = address
    },
    seeDetil(row){
      Pagedetil({id:row.id}).then(res=>{
        this.detiltable = res.data
        this.detilVisible = !this.detilVisible
      })
    },
    clearsource(){
      this.form.eventName = ''
      this.form.eventId = ''
      this.form.maintenanceAddress = ''
    },
    choosePerson(item){
      this.form.maintenanceUserId = item.userId
      this.form.maintenanceUserName = item.userName
    },
    submitevents(){
      this.form.eventName = this.currentRow.eventCodeName
      this.form.eventId = this.currentRow.eventCodeId
      this.form.maintenanceAddress = this.currentRow.eventAddress
      this.eventVisible = !this.eventVisible
    },
    submitequipment(){
      this.form.eventName = this.currentRow.equipmentName
      this.form.eventId = this.currentRow.id
      this.form.maintenanceAddress = this.currentRow.equipmentHome
      this.eventVisible = !this.eventVisible
    },
    chooseCurrentChange(val){
      this.currentRow = val;
    },
    eventsSizeChange(val){
      this.eventsData.size=  val
      if(this.choosetype==1){
        this.loadeventslist(this.eventsData)
      }else {
        this.loadEquipmentlist(this.eventsData)
      }
    },
    eventsCurrentChange(val){
      this.eventsData.current=  val
      if(this.choosetype==1){
        this.loadeventslist(this.eventsData)
      }else {
        this.loadEquipmentlist(this.eventsData)
      }
    },
    handleCurrentChange(val){
      this.paramsData.current=  val
      this.loadlist(this.paramsData)
    },
    handleSizeChange(val){
      this.paramsData.size=  val
      this.loadlist(this.paramsData)
    },
    loadeventslist(obj){
      eventPage(obj).then(res=>{
        this.tableData = res.data.records
        this.eventstotal = res.data.total
      })
    },
    loadEquipmentlist(obj){
      equipmentPage(obj).then(res=>{
        this.tableData = res.data.records
        this.eventstotal = res.data.total
      })
    },
    loadlist(obj){
      maintenancePage(obj).then(res=>{
        this.GroupList = res.data.records
        this.total = res.data.total
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    submitfrom(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        savelistroup(this.form).then(res=>{
          if (res.code === 200) {
            this.$message.success(res.msg)
            this.dialogVisible = false
            this.loadlist(this.paramsData)
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    MutiDeleteFun(){
      if(this.multipleSelection.length>0){
        this.$confirm(`是否批量删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          let arr=[]
          this.multipleSelection.forEach(item=>{
            arr.push(item.id)
          })
          deleteBatchgroup({ids:arr}).then((res)=>{
            if(res.code===200){
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.paramsData.current = 1
              this.loadlist(this.paramsData)
            }else {
              this.$message({
                message: '删除失败',
                type: 'error'
              });
            }
          })
        })
      }else {
        this.$message.error('未选择删除项目')
      }
    },
    search(){
      if(this.DataPicker.length>0){
        this.paramsData.startTime =  this.DataPicker[0]
        this.paramsData.endTime =  this.DataPicker[1]
      }
      this.paramsData.current = 1
      this.loadlist(this.paramsData)
    },
    resize(){
      this.paramsData={
        current:1,
        size: 100,
        searchText:'',
        maintenanceStatus:'-1',
        maintenanceSource:'-1'
      }
      this.loadlist(this.paramsData)
    },
    addGroup(){
      this.dialogVisible = !this.dialogVisible
      ByPostpeople({code:'repirePerson'}).then(res=>{
        this.maintenList = res.data
      })
    },
    chooseEvent(){
      this.choosetype = 1
      this.eventsData.current = 1
      this.eventVisible = !this.eventVisible
      this.loadeventslist(this.eventsData)
    },
    chooseEquipment(){
      this.choosetype = 2
      this.eventsData.current = 1
      this.eventVisible = !this.eventVisible
      this.loadEquipmentlist(this.eventsData)
    },
    clearnF(form){
      this.form={
        groupName:'',
        sorting:'',
        description:'',
        generalStatus:1,
        maintenanceSource:'0',
      }
      this.$refs[form].resetFields()
    },
    del(row){
      this.$confirm(`是否删除该设备维护记录`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        let id = []
        id.push(row.id)
        deleteBatchgroup({ids:id}).then((e)=>{
          if(e.code===200){
            this.$message.success(e.msg)
            this.loadlist(this.paramsData)
          }else{
            this.$message.error(e.msg)
          }
        })
      })
    },
  }
}
</script>

<style scoped lang="less">
::v-deep.el-table__body tr.current-row>td {
  background: #F56C6C!important;
  color: #FFFFFF;
}
</style>
